/* eslint-disable max-len */
import { RISK_SECTION_HIGH, RISK_SECTION_LOW } from '@consts/risk';
import {
  formatResult,
  formatRisk,
  formatYesNoQuestion,
} from '@/src/common/helpers/quiz';

export const riskSettings = [
  formatRisk(RISK_SECTION_LOW, 'common.low', 'risk.low', 0, 1),
  formatRisk(RISK_SECTION_HIGH, 'common.high', 'risk.high', 2, 99999),
];

export const resultSettings = [
  formatResult(
    'Based on the information you provided, you have a low risk of falling. If you feel unsteady or believe that you are at risk for falling, we recommend that you consult with a healthcare provider for further evaluation.',
    '',
    0,
    1,
  ),
  formatResult(
    'Based on the information you provided, you have a high risk of falling. If you experience a decline in balance and feel unsteady, it is crucial to seek medical attention. A professional assessment can identify underlying causes and create an individualized plan for balance training and rehabilitation.',
    'We recommend seeking professional care for targeted assistance in enhancing both your balance and strength through specialized training.This not only lowers the chances of falling but also boosts your overall health and well-being.',
    2,
    99999,
  ),
];

export const questions = [
  formatYesNoQuestion(
    'question1',
    'Have you fallen (unintentionally coming to rest on the ground or other lower level) in the past 12 months?',
    2,
    0,
    0,
    0,
    'People who have fallen once are likely to fall again',
  ),
  formatYesNoQuestion(
    'question2',
    'Are you using or have you been advised to use assistive devices such as cane or walker?',
  ),
  formatYesNoQuestion(
    'question3',
    'Do you feel unsteady when standing or walking?',
    2,
    0,
    0,
    0,
    'Unsteadiness can be a sign of poor balance which is a risk factor for falling',
  ),
  formatYesNoQuestion(
    'question4',
    'Do you worry about falling?',
    2,
    0,
    0,
    0,
    ' If you’re worried about falling you’re more likely to fall.',
  ),
  formatYesNoQuestion(
    'question5',
    'Do you need to push with your hands when getting up from a chair?',
    1,
    0,
    0,
    0,
    'This can be a sign of weak leg muscles which is a risk factor for falling.',
  ),
  formatYesNoQuestion(
    'question6',
    'Do you take medicine that sometimes makes you feel dizzy, light-headed or more tired?',
    1,
    0,
    0,
    0,
    'Side effects from medication can increase the risk of falling.',
  ),
  formatYesNoQuestion(
    'question7',
    'Do you regularly feel the urge to rush to the toilet?',
    1,
    0,
    0,
    0,
    'Rushing to the bathroom, especially if it happens at night, can be a risk for falling.',
  ),
  formatYesNoQuestion(
    'question8',
    'Do you use your furniture as support when you move around in your home?',
    1,
    0,
    0,
    0,
    'Using furniture to keep upright at home can be a sign of poor balance.',
  ),
];

export const references = [];
